import {NGXLogger} from 'ngx-logger';
import {PortalMessageBroker} from '../portal/portal-message-broker';
import {Router} from '@angular/router';
import {EditFormService} from '../modules/edit-form/edit-form.service';
import {inject, Injectable} from '@angular/core';
import {from} from 'rxjs';
import {BelegService} from './beleg.service';
import {Store} from '@ngrx/store';
import * as moment from 'moment/moment';
import {AppState} from '../interfaces/app-state.interface';
import {AppContextActions} from '../store/actions/app-context.actions';
import {ZahlungActions} from '../store/actions/zahlung.actions';
import {ZahlungSelectors} from '../store/selectors/zahlung.selectors';
import {take} from 'rxjs/operators';
import {InhaberEntitiesActions, NavigationService} from '@adnova/jf-ng-components';


export interface OverviewOpenDataV1 {
  readonly inhaberId: string;
}

export interface EditZahlungDataV1 {
  readonly inhaberId: string;
  readonly zahlungId: string;
}

export interface NewZahlungDataV1 {
  readonly inhaberId: string;
  readonly belegId: string;
}

export interface DeleteZahlungDataV1 {
  readonly inhaberId: string;
  readonly zahlungId: string;
}

export interface UpdatedZahlungDataV1 {
  readonly inhaberId: string;
  readonly zahlungenIds: string[];
}

export interface CreatedZahlungDataV1 {
  readonly inhaberId: string;
  readonly zahlungenIds: string[];
}

export interface DeletedZahlungDataV1 {
  readonly inhaberId: string;
  readonly zahlungenIds: string[];
}

export interface UpdatedBetriebDataV1 {
  readonly betriebId: string;
}

@Injectable({
  providedIn: 'root'
})
export class IntentActionService {

  private _logger = inject(NGXLogger);
  private _portalMessageBroker = inject(PortalMessageBroker);
  private _store = inject(Store<AppState>);
  private _editFormService = inject(EditFormService);
  private _belegService = inject(BelegService);
  private _router = inject(Router);
  private _navigationService = inject(NavigationService);

  /**
   * Intent-Id für den Aufruf der Overview-Ansicht
   *
   * JSON Schema: /assets/intents/de.just-farming.zahlungen_overview_open_v1.schema.json
   *
   * @private
   */
  private readonly _overviewOpenIdV1 = 'de.just-farming:zahlungen:overview.open';

  /**
   * Intent-Id für den Aufruf der Edit Seite für eine bestehende Zahlung in einem Popup
   *
   * JSON Schema: /assets/intents/de.just-farming.zahlungen_popup_edit_v1.schema.json
   *
   * @private
   */
  private readonly _editOpenPopupIdV1 = 'de.just-farming:zahlungen:popup.edit';

  /**
   * Intent-Id für den Aufruf der Edit Seite für eine bestehende Zahlung
   *
   * JSON Schema: /assets/intents/de.just-farming.zahlungen_edit_open_v1.schema.json
   *
   * @private
   */
  private readonly _editOpenIdV1 = 'de.just-farming:zahlungen:edit.open';

  /**
   * Intent-Id für den Aufruf der Edit Seite für eine neue Zahlung in einem Popup
   *
   * JSON Schema: de.just-farming.zahlungen_popup_edit_new_v1.schema.json
   *
   * @private
   */
  private readonly _newZahlungPopupIdV1 = 'de.just-farming:zahlungen:new';

  /**
   * Intent-Id für den Aufruf der Löschen-Seite in einem Popup
   *
   * JSON Schema: /assets/intents/de.just-farming.zahlungen_popup_delete_v1.schema.json
   *
   * @private
   */
  private readonly _deleteZahlungPopupIdV1 = 'de.just-farming:zahlungen:popup.delete';

  /**
   * Intent-Id für den Broadcast zur Information über eine aktualisierte Zahlung
   *
   * JSON Schema: /assets/intents/de.just-farming.zahlungen_payment.updated_v1.schema.json
   *
   * @private
   */
  private readonly _updatedZahlungIdV1 = 'de.just-farming:zahlungen:payment.updated';

  /**
   * Intent-Id für den Broadcast zur Information über eine erstellte Zahlung
   *
   * JSON Schema: /assets/intents/de.just-farming.zahlungen_payment.created_v1.schema.json
   *
   * @private
   */
  private readonly _createdZahlungIdV1 = 'de.just-farming:zahlungen:payment.created';

  /**
   * Intent-Id für den Broadcast zur Information über eine gelöschte Zahlung
   *
   * JSON Schema: /assets/intents/de.just-farming.zahlungen_payment.deleted_v1.schema.json
   *
   * @private
   */
  private readonly _deletedZahlungIdV1 = 'de.just-farming:zahlungen:payment.deleted';

  /**
   * Intent-Id für den Broadcast zur Information über einen aktualisierten Betrieb
   *
   * @private
   */
  private readonly _betriebUpdatedIdV1 = 'de.just-farming:betriebsinformationen:betrieb.updated';

  constructor() {
    if (this.isRunningInPortal()) {

      // INFO: Öffnen der Übersicht-Seite
      this._portalMessageBroker.registerIntentCallback(this._overviewOpenIdV1, '1', (data => {
        this.handleOverviewOpenV1(data);
      }));

      // INFO: Öffnen der Bearbeiten-Seite
      this._portalMessageBroker.registerIntentCallback(this._editOpenIdV1, '1', (data => {
        this.handleEditZahlungV1(data);
      }));

      // INFO: Öffnen der Bearbeiten-Seite in einem Popup
      this._portalMessageBroker.registerIntentCallback(this._editOpenPopupIdV1, '1', (data => {
        this.handleEditZahlungPopupV1(data);
      }));

      // INFO: Öffnen der Bearbeiten-Seite für eine neue Zahlung
      this._portalMessageBroker.registerIntentCallback(this._newZahlungPopupIdV1, '1', (data => {
        this.handleNewZahlungV1(data);
      }));

      // INFO: Handling für das Löschen einer Zahlung
      this._portalMessageBroker.registerIntentCallback(this._deleteZahlungPopupIdV1, '1', (data => {
        this.handleDeleteV1(data);
      }));

      // INFO: Handling für eine erstellte Zahlung
      this._portalMessageBroker.registerIntentCallback(this._createdZahlungIdV1, '1', (data => {
        this.handlePaymentCreatedV1(data);
      }));

      // INFO: Handling für eine aktualisierte einer Zahlung
      this._portalMessageBroker.registerIntentCallback(this._updatedZahlungIdV1, '1', (data => {
        this.handlePaymentUpdatedV1(data);
      }));

      // INFO: Handling für eine gelöschte einer Zahlung
      this._portalMessageBroker.registerIntentCallback(this._deletedZahlungIdV1, '1', (data => {
        this.handlePaymentDeletedV1(data);
      }));

      // INFO: Betrieb aktualisiert.
      this._portalMessageBroker.registerIntentCallback(this._betriebUpdatedIdV1, '1', (data => {
        this.handleBetriebUpdated(data);
      }));

      this._portalMessageBroker.allIntentCallbackRegistered();
    }
  }

  handleOverviewOpenV1(
    data: OverviewOpenDataV1
  ): void {
    const inhaberId = data.inhaberId;

    this._router.navigate(['/overview/inhaber/' + inhaberId]);
  }

  handleEditZahlungV1(
    data: EditZahlungDataV1
  ): void {
    const inhaberId = data.inhaberId;
    const zahlungId = data.zahlungId;

    this._router.navigate(['/edit/inhaber/' + inhaberId + '/zahlung/' + zahlungId]).then(() => {
      this._store.dispatch(AppContextActions.setPopupMode({popupMode: false}));
    });
  }

  handleEditZahlungPopupV1(
    data: EditZahlungDataV1
  ): void {
    const inhaberId = data.inhaberId;
    const zahlungId = data.zahlungId;

    this._router.navigate(
      [
        '/edit/inhaber/' + inhaberId + '/zahlung/' + zahlungId
      ]
    ).then(() => {
      this._store.dispatch(AppContextActions.setPopupMode({popupMode: true}));
    });
  }

  handleNewZahlungV1(
    data: NewZahlungDataV1
  ): void {
    const inhaberId = data.inhaberId;

    this._router.navigate(
      [
        '/edit/inhaber/' + inhaberId
      ]
    ).then(() => {
      this._store.dispatch(AppContextActions.setPopupMode({popupMode: true}));
      const belegId = data.belegId;

      if (belegId) {
        this._belegService.getBeleg(inhaberId, belegId).subscribe(belegDto => {
          this._store.dispatch(ZahlungActions.assignBelegToZahlung({
            belegDto,
          }));
        });
      }
    });
  }

  handleDeleteV1(
    data: DeleteZahlungDataV1
  ): void {
    this._store.select(ZahlungSelectors.zahlungDeleteDialogOpen).subscribe(open => {
      if (open) {
        this._router.navigate(
          [
            '/delete-dialog/inhaber/' + data.inhaberId + '/zahlung/' + data.zahlungId
          ]
        );
      }
    });

    this._store.dispatch(ZahlungActions.openZahlungDeleteEmbedded({
      inhaberId: data.inhaberId,
      zahlungId: data.zahlungId,
    }));
  }

  /**
   * Prüft, ob die App im Portal läuft
   */
  public isRunningInPortal(): boolean {
    return this._portalMessageBroker.isRunningInPortal();
  }

  /**
   * Öffnet über einen Intent die Übersicht-Seite mit der Tabelle der Zahlungen
   *
   * Befindet sich die Anwendung nicht im Portal, wird ein Fehler geworfen.
   * Es sollte vorher geprüft werden, ob die Anwendung im Portal ausgeführt wird {@link isRunningInPortal}.
   *
   * @param data Benötigte Daten für den Aufruf
   */
  public doOverviewOpenV1(
    data: OverviewOpenDataV1,
  ): void {
    this.doEmit(this._overviewOpenIdV1, '1', data);
  }

  /**
   * Öffnet über einen Intent die Bearbeiten-Seite zu einer Zahlung
   *
   * Befindet sich die Anwendung nicht im Portal, wird ein Fehler geworfen.
   * Es sollte vorher geprüft werden, ob die Anwendung im Portal ausgeführt wird {@link isRunningInPortal}.
   *
   * @param data Benötigte Daten für den Aufruf
   */
  public doEditOpenV1(
    data: EditZahlungDataV1,
  ): void {
    this.doEmit(this._editOpenIdV1, '1', data);
  }

  /**
   * Informiert andere Apps über die Aktualisierung einer Zahlung per Broadcast Hintergrund-Intent
   *
   * Befindet sich die Anwendung nicht im Portal, wird ein Fehler geworfen.
   * Es sollte vorher geprüft werden, ob die Anwendung im Portal ausgeführt wird {@link isRunningInPortal}.
   *
   * @param data Benötigte Daten für den Aufruf
   */
  public doZahlungUpdatedV1(
    data: UpdatedZahlungDataV1,
  ): void {
    this.doEmit(this._updatedZahlungIdV1, '1', data);
  }

  /**
   * Informiert andere Apps über das Erstellen einer Zahlung per Broadcast Hintergrund-Intent
   *
   * Befindet sich die Anwendung nicht im Portal, wird ein Fehler geworfen.
   * Es sollte vorher geprüft werden, ob die Anwendung im Portal ausgeführt wird {@link isRunningInPortal}.
   *
   * @param data Benötigte Daten für den Aufruf
   */
  public doZahlungCreatedV1(
    data: CreatedZahlungDataV1,
  ): void {
    this.doEmit(this._createdZahlungIdV1, '1', data);
  }

  /**
   * Informiert andere Apps über das Löschen einer Zahlung per Broadcast Hintergrund-Intent
   *
   * Befindet sich die Anwendung nicht im Portal, wird ein Fehler geworfen.
   * Es sollte vorher geprüft werden, ob die Anwendung im Portal ausgeführt wird {@link isRunningInPortal}.
   *
   * @param data Benötigte Daten für den Aufruf
   */
  public doPaymentDeletedV1(
    data: DeletedZahlungDataV1,
  ): void {
    this.doEmit(this._deletedZahlungIdV1, '1', data);
  }

  /**
   * Schließt ein Intent Popup
   *
   * Befindet sich die Anwendung nicht im Portal, wird ein Fehler geworfen.
   * Es sollte vorher geprüft werden, ob die Anwendung im Portal ausgeführt wird {@link isRunningInPortal}.
   */
  public doCloseDialogV1(): void {
    this.doClose();
  }

  private doClose(): void {
    if (!this.isRunningInPortal()) {
      throw new Error('app is not running in portal');
    }

    const promise = this._portalMessageBroker.closeIntent();
    from(promise)
      .subscribe(
        value => {
          this._logger.debug('IntentActionService.doClose(): handles successful', value);
        },
        error => {
          this._logger.debug('IntentActionService.doClose(): closing failed', error);
        },
      );
  }

  handlePaymentCreatedV1(
    data: UpdatedZahlungDataV1
  ): void {
    this._logger.info('Info über erstellte Zahlung ist in Zahlung angekommen:', data);
    this._store.dispatch(AppContextActions.setLastIntentZahlungCreated({
      lastIntentZahlungCreated: moment().toISOString()
    }));

    this._store.dispatch(ZahlungActions.forceReloadUtilityWidget());
  }

  handlePaymentUpdatedV1(
    data: UpdatedZahlungDataV1
  ): void {
    this._logger.info('Info über aktualisierte Zahlung ist in Zahlung angekommen:', data);
    this._store.dispatch(AppContextActions.setLastIntentZahlungUpdated({
      lastIntentZahlungUpdated: moment().toISOString()
    }));

    this._store.dispatch(ZahlungActions.forceReloadUtilityWidget());
  }


  handlePaymentDeletedV1(
    data: UpdatedZahlungDataV1
  ): void {
    this._logger.info('Info über löschen einer Zahlung ist in Zahlung angekommen:', data);

    /*
     * INFO: Wenn der aktuell angezeigte Inhaber gleich dem Inhaber der gelöschten Zahlung ist, soll die Tabelle neu
     * geladen werden.
     */
    const currentInhaberId = this._navigationService.currentInhaber?.id;
    if (currentInhaberId === data.inhaberId) {
      this._store.dispatch(ZahlungActions.reloadZahlungDtos());

      /*
       * INFO: Wenn die aktuell angezeigte Zahlung gelöscht wurde, soll eine Overlay-Meldung angezeigt werden, die den
       * User darauf hinweist. Die aktuell ausgewählte Zahlung kann nicht mehr weiter bearbeitet werden, da diese ja
       * gelöscht wurde.
       */
      this._router.routerState.root.firstChild?.firstChild?.paramMap.pipe(
        take(1),
      ).subscribe(paramMap => {
        const zahlungId = paramMap.get('zahlungId');
        if (data.zahlungenIds.find(id => id === zahlungId)) {
          this._store.dispatch(ZahlungActions.openZahlungDeletedDialog({
            inhaberId: currentInhaberId,
            zahlungId: zahlungId || '',
          }));
        }
      });
    }

    // INFO: Das Badge soll in jedem Fall aktualisiert werden, da es immer angezeigt wird.
    this._store.dispatch(ZahlungActions.countBadgeValue({inhaberId: data.inhaberId}));

    //INFO: Auch das Widget soll in jedem Fall alle Daten aktualisieren.
    this._store.dispatch(ZahlungActions.forceReloadUtilityWidget());
  }

  /**
   * Löst das Aktualisieren der Daten des Betriebs aus.
   *
   * @param data
   */
  handleBetriebUpdated(
    data: UpdatedBetriebDataV1,
  ): void {
    this._logger.debug('betrieb updated intent received for betriebId:', data.betriebId);

    // INFO: Die Daten der Betriebe in der Betriebsauswahl müssen aktualisiert werden.
    this._store.dispatch(InhaberEntitiesActions.loadInhabers());
  }


  private doEmit(
    intentId: string,
    intentVersion: string,
    data: any,
  ): void {
    if (!this.isRunningInPortal()) {
      throw new Error('app is not running in portal');
    }

    const promise = this._portalMessageBroker.emitIntent(intentId, intentVersion, data);
    from(promise)
      .subscribe(
        value => {
          this._logger.debug('IntentActionService.doEmit(): id=' + intentId + ' version=' + intentVersion + ' handles successful', value);
        },
        error => {
          this._logger.debug('IntentActionService.doEmit(): id=' + intentId + ' version=' + intentVersion + ' dispatch failed', error);
        },
      );
  }
}
