import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ValidatorService} from 'angular-iban';
import {NgxCurrencyInputMode} from 'ngx-currency';
import {EmpfaengerDTO, ZahlungCreateRequestDTO, ZahlungUpdateRequestDTO} from '../../../openapi/zahlung-openapi';
import {BehaviorSubject, combineLatest, Observable, of, Subject} from 'rxjs';
import {debounceTime, filter, map, take, takeUntil} from 'rxjs/operators';
import {FormControls} from './form';
import {Store} from '@ngrx/store';
import * as moment from 'moment/moment';
import {Moment} from 'moment';
import {IbanSuggestion} from './iban-suggestion';
import {IbanPipe} from '../../../pipes/iban-pipe/iban.pipe';
import {validateIBAN} from 'ngx-iban-validator';
import {formatCurrency} from '@angular/common';
import {AppState} from '../../../interfaces/app-state.interface';
import {ZahlungSelectors} from '../../../store/selectors/zahlung.selectors';
import {ZahlungActions} from '../../../store/actions/zahlung.actions';
import {NavigationService} from '@adnova/jf-ng-components';
import {NGXLogger} from 'ngx-logger';


@Component({
  selector: 'zlng-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  providers: [IbanPipe],
})
export class FormComponent implements OnInit, OnDestroy {

  @Output()
  public highlight = new EventEmitter<string[]>();

  public validationRequested = false;

  public validationRequestedAnimation = false;

  private readonly unsubscribe$ = new Subject<void>();

  private highlighted = '';

  public formControls: FormControls = {
    empfaenger: new UntypedFormControl('', [Validators.required, Validators.maxLength(70)]),
    iban: new UntypedFormControl('', [Validators.required, ValidatorService.validateIban]),
    bank: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(60)
    ]),
    bic: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(11)
    ]),
    betrag: new UntypedFormControl('', [Validators.required, Validators.min(0.01)]),
    verwendungszweck: new UntypedFormControl('', [Validators.maxLength(140)]),
    faelligkeitsdatum: new UntypedFormControl(),
  };

  public editForm = new UntypedFormGroup(this.formControls);

  public currencyMaskInputMode = NgxCurrencyInputMode.Natural;

  /*
   * INFO: Unicode-Werte der Umlaute.
   * Ä: \u00C4
   * ä: \u00E4
   * Ö: \u00D6
   * ö: \u00F6
   * Ü: \u00DC
   * ü: \u00FC
   * ß: \u00DF
   */
  public sepaRegex = new RegExp(/^[0-9a-zA-Z\u00C4\u00D6\u00DC\u00E4\u00F6\u00FC\u00DF\/?:().,'+ -]*$/);

  public ibanOptionsAll?: IbanSuggestion[];

  public filteredIbanOptions?: Observable<IbanSuggestion[]>;

  public faelligkeitsdatumFocus$ = new BehaviorSubject<boolean>(false);

  constructor(
    private store: Store<AppState>,
    private ibanPipe: IbanPipe,
    private navigationService: NavigationService,
    private logger: NGXLogger,
  ) {
  }

  ngOnInit(): void {

    // INFO: Handling für Iban-Vorschläge

    this.store.select(ZahlungSelectors.belegSuggestedIbans).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(ibanSuggestions => {
      if (ibanSuggestions && ibanSuggestions.candidates) {
        for (const candidate of ibanSuggestions.candidates) {
          this.store.dispatch(ZahlungActions.loadBankinfoFromIban({iban: candidate}));
        }
      }
    });

    this.store.select(ZahlungSelectors.suggestedIbansBankInfo).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(suggestions => {
      if (suggestions) {
        this.ibanOptionsAll = suggestions;
        this.filteredIbanOptions = of(suggestions);

        if (suggestions.length === 1 && this.formControls.iban.value.length === 0) {
          this.formControls.iban.setValue(suggestions[0].iban);
        }
      }
    });

    // INFO: Handling für Verwendungszweck-Vorschläge

    this.store.select(ZahlungSelectors.suggestedPurpose).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(purpose => {
      if (purpose && this.formControls.verwendungszweck.value.length === 0) {
        this.formControls.verwendungszweck.setValue(purpose);
      }
    });

    // BIC & BANK Handlers ----------------------------------------------------

    /* INFO: BIC wird automatisch durch den Effekt loadBankinfo$ ermittelt.
       Bei Erfolg wird hier der Wert in das Feld eingetragen. */
    combineLatest([
      this.store.select(ZahlungSelectors.bankinfoBic),
      this.store.select(ZahlungSelectors.bic),
    ]).pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(0),
    ).subscribe(([bankInfoBic, zahlungBic]) => {
      this.setBicFormField(bankInfoBic, zahlungBic);
    });

    /* INFO: Bankbezeichnung wird automatisch durch den Effekt loadBankinfo$ ermittelt.
        Bei Erfolg wird hier der Wert in das Feld eingetragen. */
    combineLatest([
      this.store.select(ZahlungSelectors.bankinfoBank),
      this.store.select(ZahlungSelectors.bank),
    ]).pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(0),
    ).subscribe(([bankinfoBank, zahlungBank]) => {
      this.setBankFormField(bankinfoBank, zahlungBank);
    });

    this.store.select(ZahlungSelectors.bankinfoLoading).pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(0),
    ).subscribe(isLoading => {
      if (isLoading) {
        this.formControls.bic.disable();
        this.formControls.bank.disable();
      } else {
        combineLatest([
          this.store.select(ZahlungSelectors.bankinfoBank),
          this.store.select(ZahlungSelectors.bank),
          this.store.select(ZahlungSelectors.bankinfoBic),
          this.store.select(ZahlungSelectors.bic),
        ]).pipe(
          take(1)
        ).subscribe(([
                       bankinfoBank,
                       zahlungBank,
                       bankinfoBic,
                       zahlungBic,
                     ]) => {
          this.setBankFormField(bankinfoBank, zahlungBank);
          this.setBicFormField(bankinfoBic, zahlungBic);
        });
      }
    });

    // EMPFÄNGER Handlers ----------------------------------------------------

    // Ändert sich der Wert von außen, wird er in das Formularfeld geschrieben.
    // Es wird aber keine Änderung des Formularfeldes getriggert, da dies sonst
    // zu einem Infinute-Loop kommen würde.
    this.store.select(ZahlungSelectors.empfaenger).pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(0),
    ).subscribe(empfaenger => {
      const converted = this.convertSpecialCharacters(empfaenger?.bezeichnung || '');
      this.formControls.empfaenger.setValue(converted, {emitEvent: false});
    });

    // Kommt es zu einer Änderung im Formularfeld, wird diese mit dem aktuellen Wert
    // verglichen. Sind die Werte unterschiedlich, wird der neue Wert in den Store
    // geschrieben.
    this.formControls.empfaenger.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(300),
    ).subscribe(empfaengerbezeichnungValue => {
      const converted = this.convertSpecialCharacters(empfaengerbezeichnungValue);

      if (converted !== empfaengerbezeichnungValue) {
        this.formControls.empfaenger.setValue(converted);
      } else {
        this.store.select(ZahlungSelectors.empfaengerbezeichnung).pipe(
          take(1),
          debounceTime(0),
        ).subscribe(empfaengerbezeichnung => {
          if (empfaengerbezeichnung !== empfaengerbezeichnungValue) {
            this.store.dispatch(
              ZahlungActions.changedFormEmpfaenger({
                empfaengerDto: {
                  id: undefined,
                  bezeichnung: empfaengerbezeichnungValue,
                } || undefined,
              })
            );
          }
        });
      }
    });

    // IBAN Handlers ---------------------------------------------------------

    // Ändert sich der Wert von außen, wird er in das Formularfeld geschrieben.
    // Es wird aber keine Änderung des Formularfeldes getriggert, da dies sonst
    // zu einem Infinute-Loop kommen würde.
    this.store.select(ZahlungSelectors.iban).pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(0),
    ).subscribe(iban => {
      const formattedIban = this.ibanPipe.transform(iban);
      this.formControls.iban.setValue(formattedIban, {emitEvent: false});
    });

    // Kommt es zu einer Änderung im Formularfeld, wird diese mit dem aktuellen Wert
    // verglichen. Sind die Werte unterschiedlich, wird der neue Wert in den Store
    // geschrieben.
    this.formControls.iban.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(300),
      map(ibanForm => this.replaceAllWhitespace(ibanForm)),
    ).subscribe(ibanForm => {

      if (ibanForm.length > 0) {
        const inhaberId = this.navigationService.currentInhaber?.id;
        if (inhaberId) {
          this.store.dispatch(ZahlungActions.readBankSuggestions({inhaberId, iban: ibanForm}));
        } else {
          this.logger.error('Keine gültige InhaberId vorhanden.');
        }
      }

      this.store.select(ZahlungSelectors.iban).pipe(
        take(1),
        debounceTime(0),
      ).subscribe(ibanStore => {

        if (ibanStore !== ibanForm) {
          this.store.dispatch(
            ZahlungActions.changedFormIban({
              iban: ibanForm || undefined,
            })
          );
        } else {
          // INFO: Sofern sich die iban inhaltlich nicht geändert hat, muss diese trotzdem ins Format gebracht werden

          const formattedIban = this.ibanPipe.transform(ibanForm);
          this.formControls.iban.setValue(formattedIban, {emitEvent: false});
        }
      });

      if (this.ibanOptionsAll) {
        this.filteredIbanOptions = of(this.ibanOptionsAll.filter(option => {
          return option.iban.includes(ibanForm);
        }));
      }
    });

    // BETRAG Handlers -------------------------------------------------------

    // Ändert sich der Wert von außen, wird er in das Formularfeld geschrieben.
    // Es wird aber keine Änderung des Formularfeldes getriggert, da dies sonst
    // zu einem Infinute-Loop kommen würde.
    this.store.select(ZahlungSelectors.betrag).pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(0),
    ).subscribe(betrag => {
      this.formControls.betrag.setValue(betrag, {emitEvent: false});
    });

    // Kommt es zu einer Änderung im Formularfeld, wird diese mit dem aktuellen Wert
    // verglichen. Sind die Werte unterschiedlich, wird der neue Wert in den Store
    // geschrieben.
    this.formControls.betrag.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(300),
    ).subscribe(betragForm => {
      this.store.select(ZahlungSelectors.betrag).pipe(
        take(1),
        debounceTime(0),
      ).subscribe(betragStore => {
        if (betragStore !== betragForm) {
          this.store.dispatch(
            ZahlungActions.changedFormBetrag({
              betrag: betragForm || undefined,
            })
          );
        }
      });
    });

    // VERWENDUNGSZWECK Handlers ---------------------------------------------

    // Ändert sich der Wert von außen, wird er in das Formularfeld geschrieben.
    // Es wird aber keine Änderung des Formularfeldes getriggert, da dies sonst
    // zu einem Infinute-Loop kommen würde.
    this.store.select(ZahlungSelectors.verwendungszweck).pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(0),
    ).subscribe(zweck => {
      const converted = this.convertSpecialCharacters(zweck);
      this.formControls.verwendungszweck.setValue(converted, {emitEvent: false});
    });

    // Kommt es zu einer Änderung im Formularfeld, wird diese mit dem aktuellen Wert
    // verglichen. Sind die Werte unterschiedlich, wird der neue Wert in den Store
    // geschrieben.
    this.formControls.verwendungszweck.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(300),
    ).subscribe(verwendungszweckForm => {
      this.store.select(ZahlungSelectors.verwendungszweck).pipe(
        take(1),
        debounceTime(0),
      ).subscribe(verwendungszweckStore => {
        if (verwendungszweckStore !== verwendungszweckForm) {
          this.store.dispatch(
            ZahlungActions.changedFormVerwendungszweck({
              verwendungszweck: verwendungszweckForm || undefined,
            })
          );
        }
      });
    });

    // FÄLLIGKEITSDATUM Handlers ---------------------------------------------

    // Ändert sich der Wert von außen, wird er in das Formularfeld geschrieben.
    // Es wird aber keine Änderung des Formularfeldes getriggert, da dies sonst
    // zu einem Infinute-Loop kommen würde.
    this.store.select(ZahlungSelectors.faelligkeitsdatum).pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(0),
    ).subscribe(faelligkeitsdatum => {
      if (faelligkeitsdatum) {
        this.formControls.faelligkeitsdatum.setValue(moment(faelligkeitsdatum), {emitEvent: false});
      } else {
        this.formControls.faelligkeitsdatum.setValue(undefined, {emitEvent: false});
      }
    });

    /* Kommt es zu einer Änderung im Formularfeld, wird diese mit dem aktuellen Wert
     * verglichen. Sind die Werte unterschiedlich, wird der neue Wert in den Store
     * geschrieben.
     *
     * Filter: Änderungen werden erst dann validieren, wenn der Focus nicht im Feld ist.
     * Dadurch wird verhindert, dass der Cursor während der Tastatureingabe springt.
     */
    combineLatest([
      this.formControls.faelligkeitsdatum.valueChanges,
      this.faelligkeitsdatumFocus$
    ]).pipe(
      takeUntil(this.unsubscribe$),
      filter(() => !this.faelligkeitsdatumFocus$.value),
      map(([date]) => {
        let faelligkeitsdatum = '';
        if (date) {
          faelligkeitsdatum = (date as moment.Moment).format('YYYY-MM-DD');
        }
        return faelligkeitsdatum;
      }),
    ).subscribe(faelligkeitsdatumForm => {
      this.store.select(ZahlungSelectors.faelligkeitsdatum).pipe(
        take(1),
        debounceTime(0),
      ).subscribe(faelligkeitsdatumStore => {
        if (faelligkeitsdatumStore !== faelligkeitsdatumForm) {
          this.store.dispatch(
            ZahlungActions.changedFormFaelligkeitsdatum({
              faelligkeitsdatum: faelligkeitsdatumForm || undefined,
            })
          );
        }
      });
    });

    this.formControls.verwendungszweck.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(verwendungszweckValue => {
      const converted = this.convertSpecialCharacters(verwendungszweckValue);

      if (converted !== verwendungszweckValue) {
        this.formControls.verwendungszweck.setValue(converted);
      }
    });

    // INFO: Common store selections

    combineLatest([
      this.store.select(ZahlungSelectors.angewiesen),
      this.store.select(ZahlungSelectors.notEditable),
    ]).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(([angewiesen, isNotEditable]) => {

      if (angewiesen || isNotEditable) {
        this.editForm.disable({emitEvent: false});
      } else {
        this.editForm.enable({emitEvent: false});

        this.formControls.bank.disable();
        this.formControls.bic.disable();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Ersetzt die Umlaute im Text.
   *
   * @param text
   * @private
   */
  private convertSpecialCharacters(text: string) {
    return text
      .replace(/ä/g, 'ae')
      .replace(/Ä/g, 'Ae')
      .replace(/ö/g, 'oe')
      .replace(/Ö/g, 'Oe')
      .replace(/ü/g, 'ue')
      .replace(/Ü/g, 'Ue')
      .replace(/ß/g, 'ss')
      .replace(/&/g, 'und');
  }

  createRequestDTO(): ZahlungCreateRequestDTO | ZahlungUpdateRequestDTO {
    const faelligkeitsdatumValue = this.formControls.faelligkeitsdatum.value;

    let faelligkeitsdatum = '';
    if (faelligkeitsdatumValue) {
      faelligkeitsdatum = (faelligkeitsdatumValue as moment.Moment).format('YYYY-MM-DD');
    }

    const empfaenger: EmpfaengerDTO = {
      id: undefined,
      bezeichnung: this.formControls.empfaenger.value,
    };

    /*
     * INFO: Sollte die EmpfaengerId im Store gesetzt sein, handelt es sich um einen ADNOVA+ Stammdatenpartner.
     * Da diese Information nicht verloren gehen soll, muss die wieder Id an den Service mitgeschickt werden.
     * Dies darf nur passieren, sofern der Anwender die Empfängerbezeichnung nicht händisch verändert.
     */
    this.store.select(ZahlungSelectors.empfaengerId).pipe(
      take(1),
    ).subscribe(empfaengerId => {
      if (empfaengerId) {
        empfaenger.id = empfaengerId;
      }
    });

    const requestDTO: ZahlungCreateRequestDTO | ZahlungUpdateRequestDTO = {
      iban: this.replaceAllWhitespace(this.formControls.iban.value),
      betrag: this.formControls.betrag.value,
      empfaenger,
      faelligkeitsdatum: faelligkeitsdatum ? faelligkeitsdatum : undefined,
      bic: this.formControls.bic.value,
      bankbezeichnung: this.formControls.bank.value,
    };

    this.store.select(ZahlungSelectors.zahlungDto).pipe(
      take(1),
    ).subscribe(zahlung => {
      requestDTO.belegId = zahlung?.belegId;
    });

    const zweck = this.formControls.verwendungszweck.value;
    if (zweck && zweck.trim()) {
      requestDTO.verwendungszweck = zweck;
    }

    return requestDTO;
  }

  get valid(): boolean {
    this.editForm.markAllAsTouched();
    this.validationRequested = true;
    this.validationRequestedAnimation = true;
    setTimeout(() => this.validationRequestedAnimation = false, 500);

    // INFO: Entweder muss das Form valid sein (Zahlungsherkunft Zahlung App) oder insgesamt disabled (Zahlungsherkunft AD+)
    return this.editForm.valid || this.editForm.disabled;
  }

  /**
   * Entfernt alle Leerzeichen aus dem String.
   *
   * @param value Der zu modifizierende String.
   */
  replaceAllWhitespace(value: string): string {
    return value.replace(/\s/g, '');
  }

  setBicFormField(bankinfoBic: string, zahlungBic: string): void {
    const ibanInvalid = !!validateIBAN({value: this.formControls.iban.value})?.ibanInvalid;
    if (ibanInvalid) {
      this.formControls.bic.setValue(undefined, {emitEvent: false});
      this.formControls.bic.disable();
    } else {
      if (bankinfoBic) {
        this.formControls.bic.setValue(bankinfoBic, {emitEvent: false});
        this.formControls.bic.disable();
      } else {
        this.formControls.bic.setValue(zahlungBic, {emitEvent: false});
        this.formControls.bic.enable();
      }
    }
  }

  setBankFormField(bankinfoBank: string, zahlungBank: string): void {
    const ibanInvalid = !!validateIBAN({value: this.formControls.iban.value})?.ibanInvalid;
    if (ibanInvalid) {
      this.formControls.bank.setValue(undefined, {emitEvent: false});
      this.formControls.bank.disable();
    } else {
      if (bankinfoBank) {
        this.formControls.bank.setValue(bankinfoBank, {emitEvent: false});
        this.formControls.bank.disable();
      } else {
        this.formControls.bank.setValue(zahlungBank, {emitEvent: false});
        this.formControls.bank.enable();
      }
    }
  }

  setHighlightedWords(highlightedFormControlName: string): void {
    let words: string[] = [];
    const value = this.editForm.get(highlightedFormControlName)?.value;
    if (typeof value === 'string') {

      //INFO: nach Worten und Wortteilen suchen
      words = value
        .split(' ')
        .filter((word: string) => word.length > 0);

    } else if (typeof value === 'number') {

      //INFO: nach Betrag in verschiedenen Darstellungsweisen suchen
      words = [
        value + '',
        (value + '').replace('.', ','),
        formatCurrency(value, 'de-de', ''),
      ];

    } else if (typeof value === 'object' && value !== null) {

      //INFO: nach Datum in verschiedenen Darstellungsweisen suchen
      const datum = value as Moment;
      words = [
        datum.format('YYYY-MM-DD'),
        datum.format('DD.MM.YYYY'),
        datum.format('DD.MM'),
        datum.format('YYYY'),
        datum.format('L'),
        datum.format('MMM'),
        datum.format('MMMM'),
      ];

    }

    this.highlight.emit(words);
  }

  startHighlightFocus(highlightedFormControlName: string): void {
    this.highlighted = highlightedFormControlName;
    this.setHighlightedWords(highlightedFormControlName);
  }

  startHighlightKeyup(highlightedFormControlName: string): void {
    if (this.highlighted !== highlightedFormControlName) {
      return;
    }

    this.setHighlightedWords(highlightedFormControlName);
  }

  clearHighlighting(): void {
    this.highlighted = '';
    this.highlight.emit([]);
  }
}
